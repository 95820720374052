import ThemeOne from './theme.json';

const typography = {
  h1: {
    fontFamily: ThemeOne.h1.fontFamily,
    fontWeight: ThemeOne.h1.fontWeight,
    fontSize: ThemeOne.h1.fontSize,
    fontStyle: ThemeOne.h1.fontStyle,
    textTransform: ThemeOne.h1.textTransform,
    color: ThemeOne.fontColor,
    lineHeight: ThemeOne.h1.lineHeight,
    letterSpacing: ThemeOne.h1.letterSpacing,
  },
  h2: {
    fontFamily: ThemeOne.h2.fontFamily,
    fontWeight: ThemeOne.h2.fontWeight,
    fontSize: ThemeOne.h2.fontSize,
    fontStyle: ThemeOne.h2.fontStyle,
    color: ThemeOne.fontColor,
    lineHeight: ThemeOne.h2.lineHeight,
    letterSpacing: ThemeOne.h2.letterSpacing,
  },
  h3: {
    fontFamily: ThemeOne.h3.fontFamily,
    fontWeight: ThemeOne.h3.fontWeight,
    fontSize: ThemeOne.h3.fontSize,
    fontStyle: ThemeOne.h3.fontStyle,
    color: ThemeOne.fontColor,
    lineHeight: ThemeOne.h3.lineHeight,
    letterSpacing: ThemeOne.h3.letterSpacing,
  },
  h4: {
    fontFamily: ThemeOne.h4.fontFamily,
    fontWeight: ThemeOne.h4.fontWeight,
    fontSize: ThemeOne.h4.fontSize,
    fontStyle: ThemeOne.h4.fontStyle,
    color: ThemeOne.fontColor,
    lineHeight: ThemeOne.h4.lineHeight,
    letterSpacing: ThemeOne.h4.letterSpacing,
  },
  h5: {
    fontFamily: ThemeOne.h5.fontFamily,
    fontWeight: ThemeOne.h5.fontWeight,
    fontSize: ThemeOne.h5.fontSize,
    fontStyle: ThemeOne.h5.fontStyle,
    color: ThemeOne.fontColor,
    textTransform: ThemeOne.h5.textTransform,
  },
  h6: {
    fontFamily: ThemeOne.h6.fontFamily,
    fontWeight: ThemeOne.h6.fontWeight,
    fontSize: ThemeOne.h6.fontSize,
    fontStyle: ThemeOne.h6.fontStyle,
    color: ThemeOne.fontColor,
    lineHeight: ThemeOne.h6.lineHeight,
    letterSpacing: ThemeOne.h6.letterSpacing,
  },
  subtitle1: {
    fontFamily: ThemeOne.subtitle1.fontFamily,
    fontWeight: ThemeOne.subtitle1.fontWeight,
    fontSize: ThemeOne.subtitle1.fontSize,
    fontStyle: ThemeOne.subtitle1.fontStyle,
    lineHeight: ThemeOne.subtitle1.linHeight,
    color: ThemeOne.fontColor,
  },
  subtitle2: {
    fontFamily: ThemeOne.subtitle2.fontFamily,
    fontWeight: ThemeOne.subtitle2.fontWeight,
    fontSize: ThemeOne.subtitle2.fontSize,
    lineHeight: ThemeOne.subtitle2.linHeight,
    color: ThemeOne.fontColor,
  },
  body: {
    fontFamily: ThemeOne.body.fontFamily,
    fontWeight: ThemeOne.body.fontWeight,
    fontSize: ThemeOne.body.fontSize,
    fontStyle: ThemeOne.body.fontStyle,
    color: ThemeOne.fontColor,
  },
  body1: {
    fontFamily: ThemeOne.body1.fontFamily,
    fontWeight: ThemeOne.body1.fontWeight,
    fontSize: ThemeOne.body1.fontSize,
    fontStyle: ThemeOne.body1.fontStyle,
    color: ThemeOne.fontColor,
    lineHeight: ThemeOne.body1.lineHeight,
    letterSpacing: ThemeOne.body1.letterSpacing,
  },
  body2: {
    fontFamily: ThemeOne.body2.fontFamily,
    fontWeight: ThemeOne.body2.fontWeight,
    fontSize: ThemeOne.body2.fontSize,
    lineHeight: ThemeOne.body2.lineHeight,
    color: ThemeOne.fontColor,
  },

  button: {
    fontFamily: ThemeOne.button.fontFamily,
    fontWeight: ThemeOne.button.fontWeight,
    fontSize: ThemeOne.button.fontSize,
    fontStyle: ThemeOne.button.fontStyle,
    textTransform: ThemeOne.button.textTransform,
  },
  caption: {
    fontFamily: ThemeOne.caption.fontFamily,
    fontWeight: ThemeOne.caption.fontWeight,
    fontSize: ThemeOne.caption.fontSize,
    lineHeight: ThemeOne.caption.lineHeight,
    letterSpacing: ThemeOne.caption.letterSpacing,
  },
  overline: {
    fontFamily: ThemeOne.overline.fontFamily,
    fontWeight: ThemeOne.overline.fontWeight,
    fontSize: ThemeOne.overline.fontSize,
    lineHeight: ThemeOne.overline.lineHeight,
    textTransform: ThemeOne.overline.textTransform,
  },
};

export default typography;
