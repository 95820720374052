import Palette from '../palette';

const MuiLink = {
  styleOverrides: {
    root: {
      color: `${Palette.common.linkColor}`,
      textAlign: 'center',
      lineHeight: '21.82px',
      marginRight: '60px !important',
      fontWeight: '300',
      '&:hover': {
        color: `${Palette.common.hoverLinkColor}`,
        fontWeight: '900',
      },
    }
  }
};

export default MuiLink;
