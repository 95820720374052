import Palette from '../palette';

const MuiStepper = {
  styleOverrides: {
    root: {
      background: 'rgba(0, 120, 161, 0.05)',
      borderRadius: '27px',
    }
  }
};

export default MuiStepper;
