const MuiMenuItem = {
  styleOverrides: {
    root: {
      fontSize: '20px',
      fontWeight: '300',
      lineHeight: '27.28px',
      letterSpecing: '0.22px',
      textAlign: 'center',
      padding: '15.5px 0px !important',
      color: 'rgba(255, 255, 255, 1)',
      display: 'block',
      borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      '&$selected': {
        padding: '11px 0 12px 16px',
        backgroundColor: '#2a4f72 !important'
      }
    },
  }
};

export default MuiMenuItem;
