import React, {lazy} from 'react';
import {BrowserRouter as WebRouter, Route, Routes as ReactRoutes} from 'react-router-dom';
import SuspenseContainer from './common/SuspenseContainer/SuspenseContainer';
import {ROUTE_ACTIONS} from './utils/Constants';

const AppLayout = lazy(() => import('./common/AppLayout/AppLayout'));

const Main = lazy(() => import('./components/Main/Main'));
const Services = lazy(() => import('./components/Services/FullSection'));
const Faqs = lazy(() => import('./components/FAQS/FullSection'));
const Blogs = lazy(() => import('./components/Blog/FullSection'));
const BlogDetails = lazy(() => import('./components/Blog/components/BlogDetails'));
const ContactUs = lazy(() => import('./components/ContactUs/ContactUs'));

/**
 * Used for decide the element to render depending on the current route of the application
 * @returns {JSX.Element}
 */
const Routes = (props) => {
    return (
        <WebRouter>
            <ReactRoutes>
                <Route
                    path={ROUTE_ACTIONS.HOME}
                    element={<SuspenseContainer>
                        <AppLayout/>
                    </SuspenseContainer>
                    }
                >
                    <Route
                        index
                        element={
                            <SuspenseContainer>
                                <Main tabName="Best pet insurance India | Pet insurance for dogs" />
                            </SuspenseContainer>}
                    />

                    <Route
                        path={ROUTE_ACTIONS.SERVICES}
                        element={
                            <SuspenseContainer>
                                <Services tabName="Services" />
                                <ContactUs isInnerView={true} />
                            </SuspenseContainer>}
                    />

                    <Route
                        path={ROUTE_ACTIONS.FAQS}
                        element={
                            <SuspenseContainer>
                                <Faqs tabName="FAQ's" />
                                <ContactUs isInnerView={true} />
                            </SuspenseContainer>}
                    />

                    <Route
                        path={ROUTE_ACTIONS.BLOGS}
                        element={
                            <SuspenseContainer>
                                <Blogs tabName="Blogs" />
                            </SuspenseContainer>}
                    />

                    <Route
                        path={ROUTE_ACTIONS.BLOG_DETAILS}
                        element={
                            <SuspenseContainer>
                                <BlogDetails tabName="Blog Details" />
                            </SuspenseContainer>}
                    />

                    <Route
                        path={ROUTE_ACTIONS.CONTACT_US}
                        element={
                            <SuspenseContainer>
                                <ContactUs tabName="Contact Us" />
                            </SuspenseContainer>}
                    />
                </Route>
            </ReactRoutes>
        </WebRouter>
    )
}

export default Routes;
