import Palette from '../palette';

const MuiStepButton = {
  styleOverrides: {
    root: {
      justifyContent: 'flex-start !important',
      color: '#fff !important'
    },
  }
};

export default MuiStepButton;
