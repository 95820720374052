import Palette from '../palette';

const MuiStep = {
  styleOverrides: {
    root: {
      borderRadius: '17px',
      padding: '5px 4px',
      width: '200px'
    }
  }
};

export default MuiStep;
