import Palette from '../palette';

const MuiAppBar = {
  styleOverrides: {
    root: {
      color: Palette.icon
    }
  }
};

export default MuiAppBar;
