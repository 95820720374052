import {createTheme} from '@mui/material/styles';

const theme = createTheme();

const MuiDialogContent = {
  styleOverrides: {
    root: {
      padding: '24px 24px 24px 24px !important',
      fontFamily: 'Nunito',
      [theme.breakpoints.down('md')]: {
        padding: '16px 16px 16px 16px !important',
      },
    }
  }
};

export default MuiDialogContent;
