import BannerOne from '../assets/banner/banner1.png';
import BannerTwo from '../assets/banner/banner2.png';
import BannerThree from '../assets/banner/banner3.png';
import BannerFour from '../assets/banner/banner4.png';
import BannerFive from '../assets/banner/banner5.png';
import BannerSix from '../assets/banner/banner6.png';
import BannerSeven from '../assets/banner/banner7.png';
import BannerEight from '../assets/banner/banner8.png';
import BannerNine from '../assets/banner/banner9.png';
import BannerTen from '../assets/banner/banner10.png';
import BannerEleven from '../assets/banner/banner11.png';

import BlogOne from '../assets/BlogOne.png'

/**
 * React app routes
 */
export const ROUTE_ACTIONS = {
    HOME: '/',
    SERVICES: '/services',
    FAQS: '/faqs',
    BLOGS: '/blogs',
    BLOG_DETAILS: '/blog/:name',
    CONTACT_US: '/contactus',
};

export const BREEDS = [
    {name: 'Bull Mastif', type: 'Giant'},
    {name: 'Great Dane', type: 'Giant'},
    {name: 'Saint bernard', type: 'Giant'},
    {name: 'Newfoundland', type: 'Giant'},
    {name: 'Leonberger', type: 'Giant'},
    {name: 'Dalmatian', type: 'Large'},
    {name: 'Doberman', type: 'Large'},
    {name: 'German Shepherd', type: 'Large'},
    {name: 'Husky Siberian', type: 'Large'},
    {name: 'Rottweiler', type: 'Large'},
    {name: 'Boxer', type: 'Large'},
    {name: 'Golden retriever', type: 'Large'},
    {name: 'Mongrel (Indian origin)', type: 'Large'},
    {name: 'Labrador', type: 'Large'},
    {name: 'Pitbull', type: 'Medium'},
    {name: 'Spitz', type: 'Medium'},
    {name: 'Bulldog', type: 'Medium'},
    {name: 'Cocker spaniel', type: 'Medium'},
    {name: 'Mudhol hound', type: 'Medium'},
    {name: 'Rajapallayam', type: 'Medium'},
    {name: 'Dachshund', type: 'Medium'},
    {name: 'Border collie', type: 'Medium'},
    {name: 'Native Indian Dogs', type: 'Medium'},
    {name: 'Lhasa Apso', type: 'Small'},
    {name: 'Pug', type: 'Small'},
    {name: 'Beagle', type: 'Small'},
    {name: 'Chihuahua', type: 'Small'},
    {name: 'Pomeranian', type: 'Small'},
    {name: 'Shih Tzu', type: 'Small'},
    {name: 'Pekingese', type: 'Small'},
];

export const SMALL_AGES = [
    {name: 2, label: '6 Months to 2 Years'},
    {name: 5, label: '2 Years to 5 Years'},
    {name: 7, label: '5 Years to 7 Years'},
    {name: 10, label: '7 Years to 10 Years'},
];

export const MEDIUM_AGES = [
    {name: 2, label: '6 Months to 2 Years'},
    {name: 5, label: '2 Years to 5 Years'},
    {name: 7, label: '5 Years to 7 Years'},
    {name: 10, label: '7 Years to 10 Years'},
];

export const LARGE_AGES = [
    {name: 2, label: '6 Months to 2 Years'},
    {name: 5, label: '2 Years to 5 Years'},
    {name: 7, label: '5 Years to 7 Years'},
    {name: 8, label: '7 Years to 8 Years'},
    {name: 9, label: '8 Years to 9 Years'},
    {name: 10, label: '9 Years to 10 Years'},
];

export const GIANT_AGES = [
    {name: 1, label: '6 Months to 1 Years'},
    {name: 3, label: '1 Years to 3 Years'},
    {name: 4, label: '3 Years to 4 Years'},
    {name: 5, label: '4 Years to 5 Years'},
    {name: 6, label: '5 Years to 6 Years'},
];

export const PET_AMOUNTS = [
    {name: 10000, label: '10,000'}, 
    {name: 20000, label: '20,000'}, 
    {name: 30000, label: '30,000'}, 
    {name: 40000, label: '40,000'}, 
    {name: 50000, label: '50,000'}, 
    {name: 60000, label: '60,000'}, 
    {name: 70000, label: '70,000'}, 
    {name: 80000, label: '80,000'}, 
    {name: 90000, label: '90,000'}, 
    {name: 100000, label: '1,00,000'}
];

export const ADDONS_LIST = [
    {id: 1, name: 'Third_Party_Cover', label: '3rd party liability cover', price: 0, checked: false},
    {id: 2, name: 'Lost_and_Stolen_Cover', label: 'Lost & stolen cover', price: 0, checked: false},
    {id: 3, name: 'Emergency_Pet_Minding', label: 'Emergency pet minding cover', price: 0, checked: false},
    {id: 4, name: 'Vet_Visits', label: 'Veterinary consultation & doctor on call cover', price: 0, checked: false},
];

export const BLOG_LIST = [
    {
      id: '1',
      Icon: <img className="blog-img" src={BannerOne} alt="How do they communicate"/>,
      title: '6 types of common disease dog might have',
      name: '6-types-of-common-disease-dog-might-have',
      description: 'Every year we see an increase in dog surgeries & number of people losing their pets to some deadly dog diseases. The common reasons for this is not knowing the signs and symptoms of these diseases and the high treatment costs and veterinary expenditures.',
    },
    {
      id: '2',
      Icon: <img className="blog-img" src={BannerTwo} alt="Good/bad foods"/>,
      title: 'What are the common type of dog surgeries',
      name: 'What-are-the-common-type-of-dog-surgeries',
      description: 'Veterinary surgery procedures fall into three broad categories: orthopaedics (bones, joints, muscles), soft tissue surgery (skin, body cavities, cardiovascular system, GI/urogenital/respiratory tracts), and neurosurgery. Advanced surgical procedures such as joint replacement (total hip, knee and elbow replacement), fracture repair, stabilization of cranial cruciate ligament deficiency, oncologic (cancer) surgery, herniated disc treatment, complicated gastrointestinal or urogenital procedures, kidney transplant, skin grafts, complicated wound management, and minimally invasive procedures (arthroscopy, laparoscopy, thoracoscopy) are performed by veterinary surgeons. Most general practice veterinarians perform routine surgeries such as neuters and minor mass excisions; some also perform additional procedures.',
    },
    // {
    //   id: '3',
    //   Icon: <img className="blog-img" src={BannerThree} alt="Why socialize our pets?"/>,
    //   title: '5 top reason why you should buy pet insurance for your furry friend',
    //   name: '5-top-reason-why-you-should-buy-pet-insurance-for-your-furry-friend',
    //   description: 'Veterinary care for our four-legged family members is a priority for pet owners, but there are times when the cost of treatment or surgery is not an option due to financial constraints. When a pet becomes ill or is injured, some pet owners are forced to cut corners or hold off on care. We hate to see a pet parent be forced to make that difficult decision. This is where pet insurance comes in. Much like medical insurance for humans, pet insurance can provide the financial peace of mind that otherwise would not be available to us in the event of an emergency.',
    // },
    {
      id: '4',
      Icon: <img className="blog-img" src={BannerFour} alt="5 Ways to Prevent Your Dog from Biting Someone"/>,
      title: '5 Ways to Prevent Your Dog from Biting Someone',
      name: '5-Ways-to-prevent-your-dog-from-biting-someone',
      description: "As a pet parent, it's impossible for you to imagine that your cute and cuddly dog can hurt someone, let alone bite them. But millions of dog bites happen every year in India and abroad. While it's okay to expect your dog to offer protection and ward off any potential intruders, you do not want him to harm innocent strangers visiting your home or approaching you."
    },
    {
      id: '5',
      Icon: <img className="blog-img" src={BannerFive} alt="Everything You Need to Know About Kennel Cough in Dogs"/>,
      title: 'Everything You Need to Know About Kennel Cough in Dogs',
      name: 'Everything-you-need-to-know-about-kennel-cough-in-dogs',
      description: 'Like humans, dogs can catch viral and bacterial infections, often showing symptoms that are similar to that of a cold or flu. If your dog is suffering from a dry cough, he or she is most likely showing symptoms of kennel cough. So, today we will be sharing some information about this contagious disease and what you should do if your dog suffers from it.',
    },
    {
      id: '6',
      Icon: <img className="blog-img" src={BlogOne} alt="Know Your Pet: Beagle - Defining Personality Traits, Care & More"/>,
      title: 'Know Your Pet: Beagle - Defining Personality Traits, Care & More',
      name: 'Know-your-pet:-beagle-Defining-personality-traits,-care-&-More',
      description: 'Vibrant, energetic, and full of life, Beagles are hard not to love! These beautiful small scent hounds love navigating through their life with their nose to the ground. With their happy-go-lucky attitude, the Beagle is a great little dog in every aspect. However, they are not the right breed for everyone. Here’s a low down on everything you need to know before making the Beagle a part of your family.',
    },
    {
      id: '7',
      Icon: <img className="blog-img" src={BannerSeven} alt="7 Reasons Why Dogs Can Foam at the Mouth"/>,
      title: '7 Reason Why Dogs Can Foam at the Mouth',
      name: '7-reason-why-dogs-can-foam-at-the-mouth',
      description: 'As a new pet parent, seeing your pet exhibiting any new behavior can be very concerning. Frothing at the mouth can be one such thing that can really trigger your protective senses. A quick search on the internet can also lead you to believe that your fur baby may possibly have Rabies. While it is a possibility, Rabies is not the only reason that can cause a dog to froth at the mouth. There are several other possible reasons that can lead to frothing, which we will discuss in today’s article.',
    },
    {
      id: '8',
      Icon: <img className="blog-img" src={BannerEight} alt="Know Your Pet: Golden Retriever - Defining Personality Traits, Care & More"/>,
      title: '9 personality traits of golden retriever',
      name: '9-personality-traits-of-golden-retriever',
      description: 'Every dog is an individual with its own quirks which makes him really special, but there’s something different about the Golden Retrievers. Goldens are an epitome of canine cuteness and so lovable. They look so dreamy and picture perfect with their strikingly beautiful golden coat. You would often find them gracing the covers of calendars, greeting cards, and posters. But, beyond all that beauty, what is so special about these Golden Retrievers that has captured the hearts and minds of millions? So, let’s do some digging and find out more about these golden beauties.',
    },
    {
      id: '9',
      Icon: <img className="blog-img" src={BannerNine} alt="Know Your Pet: Siberian Husky - Defining Personality Traits, Care & More"/>,
      title: '7 personality traits of siberian husky',
      name: '7-personality-traits-of-siberian-husky',
      description: 'With those beautiful blue eyes and gorgeous soft fur coats, huskies are stunning animals. It’s hard not to fall for these beauties. So, before you are planning on taking a Siberian Husky or have just got one, it’s important to understand them.',
    },
    {
      id: '10',
      Icon: <img className="blog-img" src={BannerTen} alt="Pet Insurance: Do You Really Need It?"/>,
      title: '5 top reason why you should buy pet insurance for your furry friend',
      name: '5-top-reason-why-you-should-buy-pet-insurance-for-your-furry-friend',
      description: 'For many years animals have had a special place in the heart of Indians. They have been represented in the art and sculptures across the country and also depicted in mythology as the vehicle or vahana of many Gods and Goddesses. So, there’s no surprise that India is the fastest-growing pet care market in the world. With increasing awareness about the good health and well-being of pets, the pet healthcare segment is also expanding.',
    },
    {
      id: '11',
      Icon: <img className="blog-img" src={BannerEleven} alt="Caring for Your Dog During the Winters"/>,
      title: '6 tips you must follow for caring of a dog in winter',
      name: '6-tips-you-must-follow-for-caring-of-a-dog-in-winter',
      description: 'Winter is here! And as the temperature drops, you may often see your dog cuddled up on the couch. With the change in weather, you need to update their winter care routine to keep them safe, healthy and protected. Today, we will tell you everything you need to know about winter care for your four-legged baby - read on!',
    }
];