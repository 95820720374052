import {createTheme} from '@mui/material/styles';

const theme = createTheme();

const MuiDialog = {
  styleOverrides: {
    paper: {
      borderRadius: '10px',
      boxShadow: '0px 0px 4px 3px rgba(0, 0, 0, 0.101262)',
      backgroundColor: '#FFFFFF',
      padding: '0px',
      minWidth: '777px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '200px',
      },
    }
  }
};

export default MuiDialog;
