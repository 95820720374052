import React from 'react';
import './App.css';
import {ThemeProvider} from '@mui/material/styles';

import Theme from './common/Theme';
import Routes from './routes';

/**
 * Return the routes
 */
const App = React.memo(() => {
  return (
    <ThemeProvider theme={Theme}>
      <Routes/>
    </ThemeProvider>
  )
});

export default App;
