const MuiButton = {
  styleOverrides: {
    root: {
      height: '40px',
      padding: '9px 23px',
      borderRadius: '24px',
    },
    startIcon: {
      marginLeft: '0px',
      '& i': {
        fontSize: '14px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    },

    endIcon: {
      marginRight: '0px',
      '& i': {
        fontSize: '14px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    },

    sizeSmall: {
      height: '34px',
      minWidth: '34px',
      borderRadius: '24px',
      padding: '9px 20px',

      '& i': {
        fontSize: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    },
  }
};

export default MuiButton;
