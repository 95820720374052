const MuiMenu = {
  styleOverrides: {
    paper: {
      backgroundColor: '#333333 !important',
      borderRadius: '0px 0px 0px 20px !important',
      top: "0px !important",
      right: "0px !important",
      minWidth: '260px'
    },
    list: {
      padding: 0,
      backgroundColor: '#333333 !important',
      borderRadius: '0px 0px 0px 20px',
      minWidth: '260px'
    }
  }
};

export default MuiMenu;
