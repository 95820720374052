import ThemeOne from './theme.json';

const white = '#ffffff';
const black = '#000000';
const primary = ThemeOne.primaryColor;
const primaryLight = ThemeOne.primaryColorLight;
const secondary = ThemeOne.secondaryColor;

const palette = {
  common: {
    black: black,
    white: white,
    fontColor: ThemeOne.fontColor,
    linkColor: ThemeOne.linkColor,
    activeLinkColor: ThemeOne.activeLinkColor,
    hoverLinkColor: ThemeOne.hoverLinkColor
  },
  type: 'light',
  primary: {
    contrastText: ThemeOne.primaryColorContrastText,
    dark: primary,
    main: primary,
    light: primaryLight,
  },
  secondary: {
    contrastText: ThemeOne.secondaryContrastText,
    dark: secondary,
    main: secondary,
    light: secondary,
  },

  disabled: {
    contrastText: ThemeOne.inactiveColor,
    dark: ThemeOne.inactiveColor,
    main: ThemeOne.inactiveColor,
    light: ThemeOne.inactiveColorLight,
  },
  error: {
    contrastText: ThemeOne.errorContrastTextColor,
    dark: ThemeOne.errorColor,
    main: ThemeOne.errorColor,
    light: ThemeOne.errorColor,
  },
  warning: {
    contrastText: ThemeOne.warningContrastTextColor,
    dark: ThemeOne.warningColor,
    main: ThemeOne.warningColor,
    light: ThemeOne.warningColor,
  },
  info: {
    contrastText: ThemeOne.infoContrastTextColor,
    dark: ThemeOne.infoColor,
    main: ThemeOne.infoColor,
    light: ThemeOne.infoColor,
  },
  success: {
    contrastText: ThemeOne.successContrastTextColor,
    dark: ThemeOne.successColor,
    main: ThemeOne.successColor,
    light: ThemeOne.successColor,
  },
  purple: {
    contrastText: ThemeOne.fontColor,
    dark: ThemeOne.purpleColor,
    main: ThemeOne.purpleColor,
    light: ThemeOne.purpleColor,
  },

  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
  },
  text: {
    primary: primary,
    secondary: secondary,
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)',
  },
  background: {
    default: ThemeOne.bodyBackground,
    paper: white,
    topMenuBackground: ThemeOne.topMenuBackground,
    leftMenuBackground: ThemeOne.leftMenuBackground
  },
  icon: ThemeOne.iconColor,
  divider: 'rgba(0, 0, 0, 0.12)',
};

export default palette;
