import Palette from '../palette';

const MuiStepLabel = {
  styleOverrides: {
    root: {
      textAlign: 'left !important',
      color: '#fff !important'
    }
  }
};

export default MuiStepLabel;
